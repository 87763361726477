import React from "react";
import {Card, CardContent, Link, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import { i18n } from "../util/i18n";
import JobAboSubscriptionModal from "./JobAboSubscriptionModal";
import SpontanbewerbungModal from "./SpontanbewerbungModal";

const SideMenu = (
    {
        filterParams,
        setFilterParams,
        attributes,
        ...props
    }
) => {
  return (
    <>
        <Stack spacing={2}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h2">
                        {i18n.translations.jobaboTitle}
                    </Typography>
                    <Typography variant="body">
                        {i18n.translations.jobaboText}
                    </Typography>
                    <JobAboSubscriptionModal
                        attributes={attributes}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                    />
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h2">
                        {i18n.translations.sideMenuPersonalTitle}
                    </Typography>
                    <Typography variant="body">
                        {i18n.translations.sideMenuPersonalText}
                    </Typography>
                    <Link
                        className="cta"
                        variant="body2"
                        tartget="_blank"
                        href={i18n.translations.sideMenuPersonalLink}
                        target="_blank"
                    >
                        {i18n.translations.sideMenuPersonalButton}
                    </Link>
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h2">
                        {i18n.translations.sideMenuSpontanTitle}
                    </Typography>
                    <Typography variant="body">
                        {i18n.translations.sideMenuSpontanText}
                    </Typography>
                    <SpontanbewerbungModal />
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h2">
                        {i18n.translations.sideMenuEducationTitle}
                    </Typography>
                    <Typography variant="body">
                        {i18n.translations.sideMenuEducationText}
                    </Typography>
                    <Link
                        className="cta"
                        variant="body2"
                        tartget="_blank"
                        href={i18n.translations.sideMenuEducationLink}
                        target="_blank"
                    >
                        {i18n.translations.sideMenuEducationButton}
                    </Link>
                </CardContent>
            </Card>

        </Stack>
    </>
  );
};

export default SideMenu;
