import React from "react";
import {
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FaArrowRight } from "react-icons/fa6";


import { i18n } from "../util/i18n";

/**
 * Renders a a JobAbo Subscriptionm Form
 * @returns JSX
 */
const SpontanbewerbungModal = ({
  loading = false,
  ...props
} = props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (loading) {
    return (
      <div>
        <Skeleton width={"100%"} height={200} variant="wave" />
      </div>
    );
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        {i18n.translations.sideMenuSpontanButton}
      </Button>
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="spontan">
          <Typography variant="h2">
              Spontanbewerbung
          </Typography>
          <p>Wähle dein Berufsgruppe und bewirb dich spontan.</p>
          <ul>
            <li>
              <a href="https://career5.successfactors.eu/career?company=kantonsspi&career_ns=job_application&career_job_req_id=1084" title="Ärztinnen / Ärzte" target="_blank">
                <FaArrowRight /> Ärztinnen und Ärzte
              </a>
            </li>
            <li>
              <a href="https://career5.successfactors.eu/career?company=kantonsspi&career_ns=job_application&career_job_req_id=1080" title="Informatik" target="_blank">
                <FaArrowRight /> Informatik
              </a>
            </li>
            <li>
              <a href="https://career5.successfactors.eu/career?company=kantonsspi&career_ns=job_application&career_job_req_id=1083" title="Medizintechnisches Personal" target="_blank">
                <FaArrowRight /> Medizintechnik
              </a>
            </li>
            <li>
              <a href="https://career5.successfactors.eu/career?company=kantonsspi&career_ns=job_application&career_job_req_id=1082" title="Medizintherapeutisches Personal" target="_blank">
                <FaArrowRight /> Medizintherapie
              </a>
            </li>
            <li>
              <a href="https://career5.successfactors.eu/career?company=kantonsspi&career_ns=job_application&career_job_req_id=1081" title="Pflegeberufe" target="_blank">
                <FaArrowRight /> Pflege
              </a>
            </li>
          </ul>        
        </Box>
      </Dialog>
    </>
  );
};

export default SpontanbewerbungModal;
