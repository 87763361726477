import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useRef } from "react";
// import our utility functions for working with filters and languages
import { i18n } from "../util/i18n";

import ResetFiltersButton from "./ResetFiltersButton";
import TextSearchFilterInput from "./TextSearchFilterInput";
import WorkloadFilterSlider from "./WorkloadFilterSlider";
import AttributeFilterAutocomplete from "./AttributeFilterAutocomplete";
import JobAboSubscriptionModal from "./JobAboSubscriptionModal";
import SubmitFiltersButton from "./SubmitFiltersButton";

import { useSettings } from "../util/SettingsContext";

/**
 * A React component holding a set of filters. Reasonable default implementation.
 * It needs the filterParams state (and setter), and passes them to the child components.
 *
 * It supports multiple common options:
 * - disbaleHeader=true: Don't show the header with the text search field
 * - disableButtons=true: Don't show the buttons to reset the filters or to subscribe to a job Abo
 * - DefaultExpanded=false: Don't show the Accordion expanded by default
 * - DisableWorkload=false: Don't show the workload slider
 *
 * The child components (e.g. filter controls) modify the filterParams state passed to them by calling
 * setFilterParams with their updated values.
 *
 * To support autoSubmit and manual submit, we keep our own local state for filterParams/setFilter state.
 *
 * If settings.autoSubmit is true, the filterParams are immediately updated, which will trigger a reload from the backend API.
 */

const FilterSection = ({
  filterParams: originalFilterParams,
  setFilterParams: setOriginalFilterParams,
  disableHeader = false,
  disableButtons = false,
  disableWorkload = false,
  attributes = null,
  defaultExpanded = false,
  totalJobs,
  ...props
}) => {
  const [settings, setSettings] = useSettings();

  const [expanded, setExpanded] = React.useState(defaultExpanded);

  // We keep our own copy of the filterParams, so we can implement autoSubmit:
  // if autoSubmit == true:
  //   - changes to filterParams are immediately submitted (e.g. the "originalFilterParams" are updated)
  // if autoSubmit == false and triggerSubmit == false:
  //  - our internal filterParams are updated, but the originalSetFilterParams is not called (e.g. no update - yet)
  // if triggerSubmit == true:
  // - if the submit or reset button have set the triggerSubmit state to true, we submit the changes
  // - we reset the triggerSubmit to false after every refresh
  const [filterParams, setFilterParams] = useState(originalFilterParams);
  const [triggerSubmit, setTriggerSubmit] = useState(true); // initially we need to submit the original filter params

  /**
   * If some of our child components change the filterParams, we need to update the originalFilterParams
   */
  useEffect(() => {
    // if triggerSubmit (or autoSubmit), we now set the original filter params and reset the triggerSubmit flag
    if (triggerSubmit || settings.autoSubmit) {
      setTriggerSubmit(false); // clear the triggerSubmit flag
      setOriginalFilterParams(filterParams); // update the original filter params
    }
  }, [filterParams]);

  // Expand / collapse of the accordion section
  const handleAccordionButton = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      {!disableHeader && (
          <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
          >
            <Grid className="textsearch" item xs={12} md={8}>
              <TextSearchFilterInput
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AttributeFilterAutocomplete
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  attributes={attributes}
                  filterAttributeId={40}
                  label={i18n.translations.filterArbeitsortLabel}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AttributeFilterAutocomplete
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  attributes={attributes}
                  filterAttributeId={5}
                  label={i18n.translations.filterAusWeiterBildung}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AttributeFilterAutocomplete
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  attributes={attributes}
                  filterAttributeId={10}
                  label={i18n.translations.filterBerufsgruppeLabel}
              />
            </Grid>           
            <Grid item xs={12} md={4}>
              <AttributeFilterAutocomplete
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  attributes={attributes}
                  filterAttributeId={15}
                  label={i18n.translations.filterDepartmentLabel}
              />
            </Grid>            
            <Grid item xs={12} md={6} className="slider-wrap">
              <WorkloadFilterSlider
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
              />
            </Grid>
            <Grid item xs={12} md={6} className="block-reset" sx={{justifyContent: "flex-end",}}>
              <ResetFiltersButton
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  setTriggerSubmit={setTriggerSubmit}
              />
            </Grid>
          </Grid>
      )}

      {!disableButtons && (
          <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={4}
          >

            {!settings.autoSubmit && (
                <Grid item>
                  <JobAboSubscriptionModal
                      attributes={attributes}
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                  />
                  <SubmitFiltersButton
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                      setTriggerSubmit={setTriggerSubmit}
                  />
                </Grid>
            )}
          </Grid>
      )}
    </>
  );
};

export default FilterSection;
