export default {
  baseUrl: "https://ohws.prospective.ch/public/v1/medium/1000745",
  jobAboSubscriptionFormUrl:
    "https://jobs.ksgr.ch/createJobAbo",

  jobsPageSize: 10,

  enableFavourites: false, // enable the favourites feature
  localStorageKeyFavourites: "prospective-jobs-favourites", // key for local storage
  jobGrid: false, // enable the job grid view
  jobGridColumns: 3, // number of columns for the job grid view (lg and up)

  autoSubmit: true, // if true, the filters will be submitted automatically when changed
  textSearchFilterDebounceTime: 500, // time in ms to wait before submitting the changes in the TextSearchFilterInput.
  /**
   * TODO
   * Please add your filders and ids here, as defined for the project.
   * Your components will be able to access these ids via Settings.filterIds['Your filter Name'] or FILTER['Your name']
   */
  filterIds: {
    Berufsgruppe: 10,
    Departement: 15,
    Arbeitsort: 40,
    ReferenceNumber: 30,
    ShortDescription: 80,
    AusWeiterbildung: 5,
  },
};