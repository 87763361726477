export default {
  de: {
    openPositions: "offene-stellen",
    searchPlaceholder: "Stelle suchen",
    resetFilters: "Filter zurücksetzen",
    submitFilters: "Suchen",
    noJobs: "Keine passende Stelle gefunden",
    workloadLabel: "Pensum",
    jobaboTitle: "JobAbo",
    jobaboText: "Mit dem JobAbo erhältst du neue Stellen direkt in dein E-Mail Postfach.",
    jobaboButtonLabel: "Job Abo",
    jobaboModalButtonLabel: "Dieses JobAbo abonnieren",
    filterBerufsgruppeLabel: "Berufsgruppe",
    filterDepartmentLabel: "Departement",
    filterArbeitsortLabel: "Arbeitsort",
    filterAusWeiterBildung: "Aus- und Weiterbildung",
    favJobsSendByEmail: "Per E-Mail versenden",
    favJobsRemoveAll: "Alle löschen",
    favJobsDrawerHeader: "Jobs gemerkt",
    favJobsEmailPrebody:
      "Schön, dass du dich für einen Job bei der KSGR interessierst.\n" +
      "\n" +
      "Hier findest du deine ausgewählten Stellen:",
    favJobsEmailPostbody:
      "Wir freuen uns, wenn du dich bei uns bewirbst. Bei Fragen zur Stelle, melde dich gern bei der im Inserat angegebenen Kontaktperson.\n" +
      "\n" +
      "Vielleicht treffen wir uns ja schon bald persönlich bei der KSGR.\n" +
      "\n" +
      "Viele Grüsse\n" +
      "Dein KSGR Recruiting Team",
    jobaboEmailLabel: "Email",
    jobaboNameLabel: "Bezeichnung",
    jobaboSubscriptionFormTitle: "JobAbo",
    jobaboSubscriptionFormText:
      "Möchtest du regelmässig über unsere neuen Stellenangebote informiert werden? Eröffne dein individuelles Stellensuchprofil, und du wirst per E-Mail automatisch über passende Stellenangebote informiert.",
    displayAsGrid: "Als Gitter anzeigen",
    jobGridColumns: "Anzahl Spalten",
    sideMenuPersonalTitle: "Personalvermittlung",
    sideMenuPersonalText: "Sie sind Personalvermittler:in und möchten uns Kandidatinnen oder Kandidaten für offene Stellen vermitteln?",
    sideMenuPersonalButton: "PERSONALVERMITTLER:INNEN",
    sideMenuPersonalLink: "https://www.ksgr.ch/personalvermittler",
    sideMenuSpontanTitle: "Spontanbewerbung",
    sideMenuSpontanText: "Nichts Passendes gefunden? Was, wenn dein Traumjob nur eine Spontanbewerbung entfernt ist?",
    sideMenuSpontanButton: "MÖGLICHE BERUFSGRUPPEN",
    sideMenuSpontanLink: "https://career5.successfactors.eu/career?company=kantonsspi&career_ns=job_application&career_job_req_id=221&jobPipeline=ksgr&canalId=1000745&publishId=8077097&view=70ca8143-0211-47eb-9ae7-358cbf80ad9e&oh_forward=1&pid=0",
    sideMenuEducationTitle: "Aus- und Weiterbildungen",
    sideMenuEducationText: "Hier findest du detaillierte Informationen zu unserem Aus- und Weiterbildungsangebot.",
    sideMenuEducationButton: "AUS- UND WEITERBILDUNGEN",
    sideMenuEducationLink: "https://www.ksgr.ch/jobs-karriere",
  },
};
